import { derived } from 'svelte/store';

import auth from './authStore';
import { listenForUser } from '$db/userDao';
import type { UserStore } from '../types';

const store = derived(
	[auth],
	([$auth], set: (value: UserStore) => void) => {
		if ($auth.user) {
			const unsubscribe = listenForUser($auth.user.uid, (user) => {
				set({
					loading: false,
					error: null,
					user
				});
			});
			return unsubscribe;
		} else {
			set({
				loading: false,
				error: 'You must be logged in to get the user info',
				user: null
			});
		}
	},
	{ loading: true, error: null, user: null }
);

export default store;
